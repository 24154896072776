import React, { useContext, useMemo } from 'react';
import { NewsGrid } from '@stereoagency/ui';
import { useQuery } from 'urql';
import flush from 'just-flush';
import useTranslation from 'next-translate/useTranslation';
import { FindAllPostSummariesQueryDocument } from '@/gql/client/graphql';
import { useFragment } from '@/gql';
import { MediaItemFieldsFragment, PostSummaryFieldsFragment, TermNodeFieldsFragment } from '@/gql/documents/fragments';
import { useCallToActions, useLangCodeFilter, useMediaItems } from '@/hooks';
import { LinesContext } from '@/components/LinesContext';

type Props = {
  data: {
    label?: string
    title?: string
    callToActions?: number[]
    limit?: number
  }
};

export default function NvisoNewsGrid({
  data: {
    label, title, callToActions, limit = 15,
  },
}: Props) {
  const langCode = useLangCodeFilter();
  const { enableLines } = useContext(LinesContext);
  const { t } = useTranslation();
  const callToActionObjects = useCallToActions(callToActions ?? [], false);
  const [{ data: postSummariesQueryData }] = useQuery({
    query: FindAllPostSummariesQueryDocument,
    variables: { first: limit, lang: langCode },
  });
  const postSummaries = useFragment(PostSummaryFieldsFragment, flush(postSummariesQueryData?.posts?.nodes ?? []));
  const mediaItems = useMediaItems(useMemo(() => (
    flush(postSummaries.map((post) => {
      const mediaItem = useFragment(MediaItemFieldsFragment, post.featuredImage?.node);
      return mediaItem?.databaseId ?? null;
    }))
  ), [postSummaries]));

  return (
    <NewsGrid
      enableLines={enableLines}
      label={label}
      title={title}
      callToActions={callToActionObjects}
      cards={flush(postSummaries.map((post) => {
        const categoriesFields = useFragment(TermNodeFieldsFragment, flush(post.categories?.nodes ?? []));
        const mediaItemFields = useFragment(MediaItemFieldsFragment, post.featuredImage?.node);
        const mediaItem = mediaItemFields?.databaseId ? mediaItems.get(mediaItemFields.databaseId) : null;
        if (!mediaItem?.mediaItemUrl) return null;

        return {
          title: post.title ?? '',
          labels: flush(categoriesFields.map((cat) => (cat.name ? {
            label: cat.name,
            href: cat.uri ?? '/',
          } : null))),
          image: {
            src: mediaItem.mediaItemUrl,
            width: mediaItem.mediaDetails?.width ?? 320,
            height: mediaItem.mediaDetails?.height ?? 200,
          },
          callToAction: {
            label: t('global:read-more'),
            href: post.uri ?? '/',
          },
        };
      }))}
    />
  );
}
